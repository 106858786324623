tbody {
    --bs-table-bg: rgba(247, 247, 248, 1);
}

thead {
    --bs-table-bg: rgba(196, 196, 196, 1);
}

body {
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.timeline_area {
    position: relative;
    z-index: 1;
}

.single-timeline-area {
    position: relative;
    z-index: 1;
    padding-left: 180px;
}

.single-timeline-area .timeline-date {
    position: absolute;
    width: 180px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 60px;
}



.single-timeline-area .timeline-date::after {
    position: absolute;
    width: 1px;
    height: 100%;
    content: "";
    background-color: #ebebeb;
    top: 0;
    right: 30px;
    z-index: 1;
}

.single-timeline-area .timeline-date::before {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #36528B;
    content: "";
    top: 50%;
    right: 26px;
    z-index: 5;
    margin-top: -5.5px;
}

.single-timeline-area .timeline-date p {
    margin-bottom: 0;
    color: #020710;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}

.single-timeline-area .single-timeline-content {
    position: relative;
    z-index: 1;
    padding: 30px 30px 25px;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    border: 1px solid #ebebeb;
}


.single-timeline-area .single-timeline-content .timeline-icon {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 30px;
    height: 30px;
    background-color: #36528B;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    text-align: center;
    /* max-width: 30px; */
    border-radius: 50%;
    margin-right: 15px;
}

.single-timeline-area .single-timeline-content .timeline-icon i {
    color: #ffffff;
    line-height: 30px;
}

.single-timeline-area .single-timeline-content .timeline-text h6 {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-timeline-area .single-timeline-content .timeline-text p {
    font-size: 13px;
    margin-bottom: 0;
}

.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
    background-color: #020710;
}

.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
    color: #3f43fd;
}

.contact-icon-container {
    /*  width: 8%; */
    margin-right: 1rem;
}

.contact-icon h5 {
    font-weight: 800;
}

.contact-icon-box {

    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    color: #ffffff;
    background: #36528B;
}

.contact-icon-box svg {
    text-align: center;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    position: relative;
    top: 5px;
}

.contact-title {
    /* margin-top: auto;
    margin-bottom: auto; */
    align-items: center;
    display: flex;
    color: #000;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.navbar-toggler:focus {

    box-shadow: none !important;
}



@media only screen and (max-width: 575px) {}

@media only screen and (max-width: 575px) {

    .single-timeline-area .single-timeline-content {
        padding: 20px;
    }

    .single-timeline-area .timeline-date {
        width: 110px;
        font-size: 12px;
    }

    .single-timeline-area {
        padding-left: 100px;
    }

    .custom-heading-shadow {
        /* display: none; */
    }

    .contact-icon .contact-icon-container {
        /*   width: 20%; */
    }

    .contact-left {
        margin-bottom: 50px;
    }
}

.row .no-x-gutter {
    --bs-gutter-x: 0rem;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}